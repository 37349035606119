<template>
  <div class="product-serve-container">
    <div class="topImg">
      <img src="@/assets/image/productServe/top.png">
    </div>
    <div class="floor1 floor" id="floor1">
      <img src="@/assets/image/productServe/f1.png" alt="">
    </div>
    <div class="floor2 floor" id="floor2">
      <div class="title">{{ floorTwoData.title }}</div>
      <div class="sub-tit">{{ floorTwoData.text }}</div>
      <div class="video">
        <video :src="floorTwoData.videoSrc" autoplay controls preload />
      </div>
      <div class="list-container">
        <div class="list-item" v-for="item in floorTwoData.listData">
          <div class="top">
            <div class="icon">
              <img :src="item.icon">
            </div>
            <div class="label">
              {{ item.text1 }}
            </div>
          </div>
          <div class="text">
            {{ item.text2 }}
          </div>

        </div>
      </div>
    </div>
    <div class="floor3 floor" id="floor3">
      <div class="title">{{ floorThreeData.title }}</div>
      <div class="sub-tit">{{ floorThreeData.text }}</div>
      <div class="softImg"> <img :src="floorThreeData.imgSrc"> </div>
      <div class="product-list">
        <div :class="productList(item, index)" v-for="(item, index ) in   floorThreeData.productList  ">
          <div class="un-open">
            <img :src="item.unOpen.bg">
            <div class="text-container">
              <img :src="item.unOpen.icon">
              <div class="text1">{{ item.unOpen.text1 }}</div>
              <div class="text2">{{ item.unOpen.text2 }}</div>
            </div>
          </div>
          <div class="opened">
            <img :src="item.opened.bg">
            <div class="text-container">
              <img :src="item.opened.icon">
              <div class="text1">{{ item.opened.text1 }}</div>
              <div class="text2">{{ item.opened.text2 }}</div>
              <div class="desc">{{ item.opened.desc }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list-container">
        <div class="list-item" v-for="  item   in   floorThreeData.listData  ">
          <div class="top">
            <div class="icon">
              <img :src="item.icon">
            </div>
            <div class="label">
              {{ item.text1 }}
            </div>
          </div>
          <div class="text">
            {{ item.text2 }}
          </div>

        </div>
      </div>
    </div>
    <div class="floor4 floor" id="floor4">
      <div class="title">{{ floorFourData.title }}</div>
      <div class="sub-tit">{{ floorFourData.text }}</div>
      <div class="tab-container">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="tab.name" :name="tab.id" v-for="tab in floorFourData.tabList" :key="tab.id">
            <el-carousel :interval="2000" arrow="always" indicator-position="outside" :autoplay="false" trigger="click"
              height="3.7rem">
              <el-carousel-item v-for="(carouse, index) in tab.carouselList" :key="index" class="carouse-item"
                :autoplay="false">
                <img :src="carouse.img">
              </el-carousel-item>
            </el-carousel>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="list-container">
        <div class="list-item" v-for="item in floorFourData.listData">
          <div class="top">
            <div class="icon"> <img :src="item.icon"> </div>
            <div class="label"> {{ item.text1 }} </div>
          </div>
          <div class="text"> {{ item.text2 }} </div>
        </div>
      </div>
    </div>
    <div class="floor5 floor" id="floor5">
      <div class="title">{{ floorFiveData.title }}</div>
      <div class="sub-tit">{{ floorFiveData.text }}</div>
      <div class="container">
        <div class="left">
          <img :src="floorFiveData.leftImg">
        </div>
        <div class="right">
          <div class="list-item" v-for="item in floorFiveData.listData">
            <div class="top">
              <div class="icon"> <img :src="item.icon"> </div>
              <div class="label"> {{ item.text1 }} </div>
            </div>
            <div class="text"> {{ item.text2 }} </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import productServe from '../assets/config/productServe'
export default {
  name: 'productServe',
  data () {
    return {
      floorTwoData: productServe.floorTwoData,
      floorThreeData: productServe.floorThreeData,
      floorFourData: productServe.floorFourData,
      floorFiveData: productServe.floorFiveData,
      activeName: ''
    }
  },
  methods: {
    productList (item, index) {
      return ['list-item', `list-item-${index + 1}`]
    },
    handleClick () { }
  },
  created () {
    this.activeName = this.floorFourData.tabList[0].id
  },
}
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
}

.product-serve-container {
  .topImg {
    height: 6rem;
    width: 100%;
  }

  .floor1 {
    height: 10.02rem;
  }

  .floor2 {
    padding: .7rem 2.6rem;
    background: #FAFAFA;

    .title {
      height: .56rem;
      font-size: .4rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #101010;
      line-height: .47rem;
      margin-bottom: .12rem;
      text-align: center;
    }

    .sub-tit {
      margin: 0 auto;
      width: 9.58rem;
      height: .48rem;
      font-size: .16rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #565E66;
      line-height: .24rem;
      margin-bottom: .2rem;
      text-align: center;
    }

    .list-container {
      margin-top: .3rem;
      display: flex;
      justify-content: space-between;

      .list-item {
        width: 4.38rem;
        padding: 32px 36px .28rem .28rem;

        /* height: 168px; */
        background: rgba(255, 255, 255, 0.65);
        box-shadow: 0px .04rem .69rem -0.23rem #CEE5F5;
        border-radius: .1rem;
        opacity: 1;
        border: .03rem solid;
        border-image: linear-gradient(138deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 3 3;

        .top {
          display: flex;
          align-items: center;

          .icon {
            width: .35rem;
            height: .35rem;
            margin-right: .1rem;
          }

          .label {
            /* width: 140px; */
            height: .28rem;
            font-size: .2rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #0560D6;
            line-height: .23rem;
          }
        }

        .text {
          width: 3.74rem;
          height: .72rem;
          font-size: .14rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #565E66;
          line-height: .24rem;
        }
      }

    }
  }

  .floor3 {
    padding: .7rem 2.6rem;

    .title {
      height: .56rem;
      font-size: .4rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #101010;
      line-height: .47rem;
      margin-bottom: .12rem;
      text-align: center;
    }

    .sub-tit {
      margin: 0 auto;
      width: 10.88rem;
      /* height: 48px; */
      font-size: .16rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #565E66;
      line-height: .24rem;
      text-align: center;
    }

    .softImg {
      width: 14.4rem;
      height: 5.27rem;
    }

    .product-list {
      position: relative;
      height: 5.34rem;

      .list-item {
        position: absolute;
        width: 25%;
        height: 2.67rem;

        .un-open {
          display: block;


          .text-container {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
              width: .6rem;
              height: .6rem;
            }

            .text1,
            .text2 {
              width: 1.44rem;
              font-size: .24rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: .32rem;
              text-align: center;
            }
          }
        }

        .opened {
          display: none;

          .text-container {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 1.1rem;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: .54rem;
              height: .54rem;
            }

            .text1,
            .text2 {
              width: 1.44rem;
              font-size: .24rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: .32rem;
              text-align: center;
            }

            .desc {
              margin-top: .26rem;
              width: 2.47rem;
              height: 1.4rem;
              font-size: .16rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: .28rem;
            }
          }
        }
      }

      .list-item-1 {
        left: 0;
        top: 0;

      }

      .list-item-2 {
        left: 25%;
        top: 0;

      }

      .list-item-3 {
        left: 50%;
        top: 0;
      }

      .list-item-4 {
        left: 75%;
        top: 0;
      }

      .list-item-5 {
        left: 0;
        top: 2.67rem
      }

      .list-item-6 {
        left: 25%;
        top: 2.67rem
      }

      .list-item-7 {
        left: 50%;
        top: 2.67rem
      }

      .list-item-8 {
        left: 75%;
        top: 2.67rem
      }

      .list-item:hover {
        .un-open {
          display: none;
        }

        .opened {
          display: block;
        }
      }

      .list-item:hover.list-item-1,
      .list-item:hover.list-item-5 {
        top: 0;
        height: 5.34rem;
        z-index: 9;
      }

      .list-item:hover.list-item-2,
      .list-item:hover.list-item-6 {
        top: 0;
        height: 5.34rem;
        z-index: 9;
      }

      .list-item:hover.list-item-3,
      .list-item:hover.list-item-7 {
        top: 0;
        height: 5.34rem;
        z-index: 9;
      }

      .list-item:hover.list-item-4,
      .list-item:hover.list-item-8 {
        top: 0;
        height: 5.34rem;
        z-index: 9;
      }
    }

    .list-container {
      margin-top: .3rem;
      display: flex;
      justify-content: space-between;

      .list-item {
        width: 3.27rem;
        height: 1.82rem;
        background: rgba(255, 255, 255, 0.28);
        box-shadow: 0px .04rem .69rem -0.23rem #CEE5F5;
        border-radius: .1rem;
        opacity: 1;
        border: .03rem solid;
        border-image: linear-gradient(138deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 3 3;
        padding: .23rem .32rem;

        .top {
          display: flex;
          align-items: center;
          margin-bottom: .08rem;

          .icon {
            width: .35rem;
            height: .35rem;
            margin-right: .1rem;
          }

          .label {
            width: 1.8rem;
            font-size: .2rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #0560D6;
            line-height: .23rem;
          }
        }

        .text {
          width: 2.63rem;
          /* height: .96rem; */
          font-size: .14rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #565E66;
          line-height: .24rem;
        }
      }

    }
  }

  .floor4 {
    padding: .7rem 0;

    .title {
      height: .56rem;
      font-size: .4rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #101010;
      line-height: .47rem;
      margin-bottom: .12rem;
      text-align: center;
    }

    .sub-tit {
      margin: 0 auto;
      /* width: 10.88rem; */
      /* height: 48px; */
      font-size: .16rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #565E66;
      line-height: .24rem;
      text-align: center;
    }

    .tab-container {
      margin-top: .2rem;



      .carouse-item {
        height: 3.76rem;
        overflow: hidden;
        /* padding-top: .3rem; */

        img {
          width: 14.4rem;
          /* height: 2.6rem; */
          margin-left: 2.4rem;
        }
      }
    }

    .list-container {
      margin-top: .3rem;
      display: flex;
      justify-content: space-between;
      padding: 0 2.6rem;

      .list-item {
        width: 3.27rem;
        height: 1.82rem;
        background: rgba(255, 255, 255, 0.28);
        box-shadow: 0px .04rem .69rem -0.23rem #CEE5F5;
        border-radius: .1rem;
        opacity: 1;
        border: .03rem solid;
        border-image: linear-gradient(138deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 3 3;
        padding: .23rem .32rem;

        .top {
          display: flex;
          align-items: center;
          margin-bottom: .08rem;

          .icon {
            width: .35rem;
            height: .35rem;
            margin-right: .1rem;
          }

          .label {
            width: 1.8rem;
            font-size: .2rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #0560D6;
            line-height: .23rem;
          }
        }

        .text {
          width: 2.63rem;
          /* height: .96rem; */
          font-size: .14rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #565E66;
          line-height: .24rem;
        }
      }

    }
  }

  .floor5 {
    background: url(../assets/image/productServe/f5_bg.png) no-repeat;
    padding: .7rem 2.6rem;

    .title {
      margin-top: 20px;
      height: .56rem;
      font-size: .4rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #101010;
      line-height: .47rem;
      margin-bottom: .12rem;
      text-align: center;
    }

    .sub-tit {
      width: 9.52rem;
      margin: 0 auto;
      font-size: .16rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #565E66;
      line-height: .24rem;
      text-align: center;
      margin-bottom: .2rem;
    }

    .container {
      display: flex;

      .left {
        max-width: 9.38rem;
        min-width: 9.38rem;
        height: 5.8rem;
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 5.5rem;
        margin-top: 0.1rem;

        .list-item {
          width: 4.38rem;
          padding: .2rem .28rem;
          background: rgba(255, 255, 255, 0.65);
          box-shadow: 0px .04rem .71rem -0.23rem #CEE5F5;
          border-radius: .1rem;
          opacity: 1;
          border: .03rem solid;
          border-image: linear-gradient(138deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 3 3;

          .top {
            display: flex;
            align-items: center;

            img {
              width: .35rem;
              height: .35rem;
            }

            .label {
              font-size: .2rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #0560D6;
              line-height: .23rem;
            }
          }

          .text {
            font-size: .14rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #565E66;
            line-height: .24rem;
          }
        }
      }
    }
  }
}
</style>

<!--  走马灯 样式 修改-->
<style lang="scss">
.product-serve-container {
  .el-carousel__arrow--left {
    left: 1.8rem;
  }

  .el-carousel__arrow--right {
    right: 1.8rem;
  }

  .tab-container {
    .el-tabs__nav-wrap::after {
      height: .01rem;
    }

    .el-tabs__header {
      margin: 0;

      .el-tabs__nav-scroll {
        height: .77rem;
        padding: 0 5rem;
        display: flex;
        justify-content: center;

        .el-tabs__active-bar {
          width: 2.06rem;
          height: .05rem;
          background: #0560D6;
          opacity: 1;
        }

        .el-tabs__item {
          padding: 0 .7rem;
          height: .6rem;
          line-height: .6rem;
          font-size: .24rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #565E66;
        }
      }
    }

    /* 指示器样式 */
    .el-tabs__content {
      .el-carousel__indicators {
        .el-carousel__indicator.is-active {
          button {
            background-color: #0560D6;
          }

        }
      }
    }
  }
}
</style>

export default {
  floorTwoData: {
    title: '物流中心集成',
    videoSrc: require("@/assets/image/productServe/video.mp4"),
    text: '累计实施物流中心集成项目超300+例，具备30万小时自动化智能化设备运行经验，以高效率、高吞吐、高柔性、高扩展为目标，提供“规划+软件+设备+ 运营”的一站式、一体化集成交付服务，满足多行业、多业主、多业态的物流模式。',
    listData: [
      {
        icon: require("@/assets/image/productServe/f2_icon_1.png"),
        text1: '高效率与高吞吐',
        text2: '以数据分析为依据，设计流程，功能布局，配套设备设施应用，提高岗位作业效率。系统控制业务和作业流程节点协同，达成高吞吐量。'
      },
      {
        icon: require("@/assets/image/productServe/f2_icon_2.png"),
        text1: '高柔性与高扩展',
        text2: '一座物流中心支持多租户，多业主、库存共享。同时支持批发、连锁、电商、三方等业态，以及药品、器械、冷链等多种类型。'
      },
      {
        icon: require("@/assets/image/productServe/f2_icon_3.png"),
        text1: '一站式与一体化',
        text2: '统筹物流业务、作业、运营、技术维度，提供一体化方案设计。从规划设计、软件研发、设备集成、实施交付到运营体系构建，导入一站式满足服务。'
      },
    ]
  },
  floorThreeData: {
    title: '供应链物流软件',
    imgSrc: require("@/assets/image/productServe/f3.png"),
    text: '物流供应链全系信息应用产品矩阵，统一技术底座、按需灵活部署及扩展，支持多层级、多租户、多仓库、多段联运，仓配一体、供应链协同运作，物流全过程可视监管、预警与追溯。现已服务客户数超2100家，系统用户7万余个，单平台用户超3000个，日均流量110余万单。',
    productList: [
      {
        unOpen: {
          bg: require("@/assets/image/productServe/f3_unOpen_bg_1.png"),
          icon: require("@/assets/image/productServe/f3_unOpen_icon_1.png"),
          text1: 'OFS',
          text2: '协同管理系统'
        },
        opened: {
          bg: require("@/assets/image/productServe/f3_opened_bg_1.png"),
          icon: require("@/assets/image/productServe/f3_opened_icon_1.png"),
          text1: 'OFS',
          text2: '协同管理系统',
          desc: '实现订单全生命周期过程管理与可视追踪，控制多层次结构、多运作主体的过程协同，智能任务调度与资源分配，降低运作成本、保障履约质量及提升客户体验。'
        }
      },

      {
        unOpen: {
          bg: require("@/assets/image/productServe/f3_unOpen_bg_2.png"),
          icon: require("@/assets/image/productServe/f3_unOpen_icon_2.png"),
          text1: 'WMS',
          text2: '仓储管理系统'
        },
        opened: {
          bg: require("@/assets/image/productServe/f3_opened_bg_2.png"),
          icon: require("@/assets/image/productServe/f3_opened_icon_2.png"),
          text1: 'WMS',
          text2: '仓储管理系统',
          desc: '实现平台化的多仓协同、仓配联动、精细化货位管理，智能化生产作业和数字化运营管理。灵活扩展适配多运营方、多货主、多业态，多作业模式的仓储管理需求。'
        }
      },

      {
        unOpen: {
          bg: require("@/assets/image/productServe/f3_unOpen_bg_3.png"),
          icon: require("@/assets/image/productServe/f3_unOpen_icon_3.png"),
          text1: 'TMS',
          text2: '运输管理系统'
        },
        opened: {
          bg: require("@/assets/image/productServe/f3_opened_bg_3.png"),
          icon: require("@/assets/image/productServe/f3_opened_icon_3.png"),
          text1: 'TMS',
          text2: '运输管理系统',
          desc: '实现运力、运务、作业过程全场景管理应用。适配网络联运，多点配送等多种模式，支持及时周期等多种策略，智能计划控制与任务调度，可视监管追溯与主动安全防范，自动统计分析，提高履约质量，降低运营成本。'
        }
      },

      {
        unOpen: {
          bg: require("@/assets/image/productServe/f3_unOpen_bg_4.png"),
          icon: require("@/assets/image/productServe/f3_unOpen_icon_4.png"),
          text1: 'BMS',
          text2: '计费管理系统'
        },
        opened: {
          bg: require("@/assets/image/productServe/f3_opened_bg_4.png"),
          icon: require("@/assets/image/productServe/f3_opened_icon_4.png"),
          text1: 'BMS',
          text2: '计费管理系统',
          desc: '标准化物流费用管理流程，精细化成本与费用核算，费用过程与运作过程一体化融化，应用数据指导经营决策。'
        }
      },

      {
        unOpen: {
          bg: require("@/assets/image/productServe/f3_unOpen_bg_5.png"),
          icon: require("@/assets/image/productServe/f3_unOpen_icon_5.png"),
          text1: 'QMS',
          text2: '质量管理分析'
        },
        opened: {
          bg: require("@/assets/image/productServe/f3_opened_bg_5.png"),
          icon: require("@/assets/image/productServe/f3_opened_icon_5.png"),
          text1: 'QMS',
          text2: '质量管理分析',
          desc: '全面采集质量数据，构建全过程的质量管理体系，打造一体化、集约化、智能化的质量分析及决策平台，助力企业高质量发展。'
        }
      },

      {
        unOpen: {
          bg: require("@/assets/image/productServe/f3_unOpen_bg_6.png"),
          icon: require("@/assets/image/productServe/f3_unOpen_icon_6.png"),
          text1: 'OAS',
          text2: '运营管理分析'
        },
        opened: {
          bg: require("@/assets/image/productServe/f3_opened_bg_6.png"),
          icon: require("@/assets/image/productServe/f3_opened_icon_6.png"),
          text1: 'OAS',
          text2: '运营管理分析',
          desc: '快速实现各分支系统的数据集中与融合，构建物流数据资产与应用体系，实现物流全景数字化、标准化的运营，提高管理的敏捷性与效率，降低管理成本。'
        }
      },
      {
        unOpen: {
          bg: require("@/assets/image/productServe/f3_unOpen_bg_7.png"),
          icon: require("@/assets/image/productServe/f3_unOpen_icon_7.png"),
          text1: 'EIP',
          text2: '企业物流门户'
        },
        opened: {
          bg: require("@/assets/image/productServe/f3_opened_bg_7.png"),
          icon: require("@/assets/image/productServe/f3_opened_icon_7.png"),
          text1: 'EIP',
          text2: '企业物流门户',
          desc: '仓储自动化设备可视化场景编排，高速协同库内设备，快速执行系统指令，监控反馈设备状态，提升整体作业效率，快速对接用户系统，满足不同业务场景需求。'
        }
      },

      {
        unOpen: {
          bg: require("@/assets/image/productServe/f3_unOpen_bg_8.png"),
          icon: require("@/assets/image/productServe/f3_unOpen_icon_8.png"),
          text1: 'WCS',
          text2: '仓库控制系统'
        },
        opened: {
          bg: require("@/assets/image/productServe/f3_opened_bg_8.png"),
          icon: require("@/assets/image/productServe/f3_opened_icon_8.png"),
          text1: 'WCS',
          text2: '仓库控制系统',
          desc: '实现上位系统和设备控制层之间指令的无缝衔接。WCS接收上位系统指令，进行拆分、重组并下达给设备执行层，快速执行上位系统指令，高速协同库内设备，提升整体作业效率。'
        }
      }
    ],
    listData: [
      {
        icon: require("@/assets/image/productServe/f3_icon_1.png"),
        text1: '完整应用、按需选择',
        text2: '从物流门户，订单管理、计费管理、仓储管理、运输管理到运营决策分析全维度系统应用覆盖，满足全过程数智化物流管控需求。'
      },
      {
        icon: require("@/assets/image/productServe/f3_icon_2.png"),
        text1: '标准产品、个性服务',
        text2: '深耕大健康领域，按“行业+业态”细分形成标准产品。产品参数可设定，策略可配置。抽象了标准功能形成组件库，面对个性需求时可灵活流程组装。'
      },
      {
        icon: require("@/assets/image/productServe/f3_icon_3.png"),
        text1: '统一底座、柔性扩展',
        text2: '分层架构模式、各子系统统一基础底座，业务服务中心化，提供技术和数据的开发平台，实现上下游系统快速集成，低代码快速开发，功能自主扩展。'
      },
      {
        icon: require("@/assets/image/productServe/f3_icon_4.png"),
        text1: '成熟稳定、灵活部署',
        text2: '持续版本迭代，丰富应用案例，系统高可用、高稳定性，支持公有云、私有云，支持混合部署，硬件资源按需扩展，降低投入及运维成本，保障数据安全。'
      },
    ]
  },
  floorFourData: {
    title: '物流智能装备',
    text: '自研穿梭车、提升机等系列产品，构建了模式自由组合、应用灵活扩展、场景广泛适配的一体化智能物流解决方案。',
    tabList: [
      {
        name: '提升机系列',
        id: 'Hoist',
        carouselList: [
          {
            img: require("@/assets/image/productServe/product-hoist-1.png"),
          },
          {
            img: require("@/assets/image/productServe/product-hoist-2.png"),
          },
          {
            img: require("@/assets/image/productServe/product-hoist-3.png"),
          },
          {
            img: require("@/assets/image/productServe/product-hoist-4.png"),
          },
        ]
      },
      {
        name: '穿梭车系列',
        id: 'shuttle',
        carouselList: [
          {
            img: require("@/assets/image/productServe/product-shuttle-1.png"),
          },
          {
            img: require("@/assets/image/productServe/product-shuttle-2.png"),
          },
          {
            img: require("@/assets/image/productServe/product-shuttle-3.png"),
          },
          {
            img: require("@/assets/image/productServe/product-shuttle-4.png"),
          },
          {
            img: require("@/assets/image/productServe/product-shuttle-5.png"),
          },
        ]
      },
      {
        name: '搬运机器人系列',
        id: 'robot',
        carouselList: [
          {
            img: require("@/assets/image/productServe/product-robot-1.png"),
          },
          {
            img: require("@/assets/image/productServe/product-robot-2.png"),
          },
          {
            img: require("@/assets/image/productServe/product-robot-3.png"),
          }
        ]
      }
    ],
    listData: [
      {
        icon: require("@/assets/image/productServe/f4_icon_1.png"),
        text1: '稳定可靠',
        text2: '自建产研中心与测试中心，ISO9001质量管理体系认证与CE欧盟安全认证。100余个项目交付落地，稳定运行。'
      },
      {
        icon: require("@/assets/image/productServe/f4_icon_2.png"),
        text1: '柔性扩展',
        text2: '深刻理解行业应用，EIC设备集成控制对设备集中管理，作业流程灵活编排、智能调度与控制协同。按需柔性扩展。'
      },
      {
        icon: require("@/assets/image/productServe/f4_icon_3.png"),
        text1: '场景适配',
        text2: '从入库、存储、搬运、分拣、复核、出库等全场景的自动化装备集成，实现仓储作业高度自动化，提升效率及准确性。'
      },
      {
        icon: require("@/assets/image/productServe/f4_icon_4.png"),
        text1: '特种支持',
        text2: '设备适应性强，可在高温、高湿度及-30℃的超低温环境下使用，在保密方面可在要求通讯加密的国防、军工领域使用。'
      },
    ]
  },
  floorFiveData: {
    title: '物流运营管理',
    text: '为行业内外部客户提供运营体系构建、精益现场管理、数智运营决策于一体的物流精益运营服务，融合技术和业务场景，以“技术+数据”赋能管控的自动化、及时性与可视化。支撑“降成本、控风险，提质量”的运营决策。',
    leftImg: require("@/assets/image/productServe/f5_left.png"),
    listData: [
      {
        icon: require("@/assets/image/productServe/f5_icon_1.png"),
        text1: '全面体系构建',
        text2: '从资源建设、作业流程、管理制度、组织职责、考核方案、人才成长6个维度诊断企业现状和需求，聚焦发展、持续、创新、文化、安全，建设物流精益运营管理体系，全面构建物流运营管理体系。'
      },
      {
        icon: require("@/assets/image/productServe/f5_icon_2.png"),
        text1: '精益现场管理',
        text2: '派驻经验丰富的专业运营人员入驻物流中心，导入7S现场管理、VI目视管理、OMB运营看板、OEC日清日毕、TLM工具模型、SAR安全评估6个管理方案，提升物流现场管理水平。'
      },
      {
        icon: require("@/assets/image/productServe/f5_icon_3.png"),
        text1: '数智运营决策',
        text2: '应用物流大数据平台与OAS决策分析系统，对资源状态、业务状态、效率履约、过程安全、问题处理进行监控预警，对资源、业务、财务、仓库、运输、客服进行统计分析，持续改善和提升运营质量。'
      }
    ]
  }
}
